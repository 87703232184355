/* eslint-disable no-underscore-dangle */
import './coac.d';
import {
  addHours,
  isAfter,
  parseISO,
} from 'date-fns';
import {
  get,
  post,
} from '../http';

const notAuthenticatedError = new Error('api token is expired, or not present. unable to get a new one');
const notAuthenticatedErrorPromise = Promise.resolve(notAuthenticatedError);

/**
 * An API layer for COAC API requests
 */
export default class COAC {
  /**
   * @param {String} api - the fully qualified domain to issue requests against
   * @param {String} postbackURL - success redirect from payment
   */
  constructor({
    api = null,
    postbackURL = '',
  }) {
    /**
     * The fully qualified base url for the MGO API
     * @type {String}
     */
    this.api = api;

    /**
     * Used during the Global Payment handoff to redirect on success
     * @type {String}
     */
    this.postbackURL = postbackURL;

    /**
     * COA order confirmation number
     * @type {String}
     */
    this.cfpsId = sessionStorage.getItem('coaccfpsid') || '';

    /**
     * The new zip code used to login
     * @type {String}
     */
    this.loginZip = sessionStorage.getItem('coaccode') || '';

    /**
     * The Visit Id of the current session
     * @type {Number}
     */
    this.visitId = null;

    /**
     * Number of login attempts made
     * @type {Number}
     */
    this.numLoginAttempts = 0;

    /**
     * Used to authorize requests against the MGO API
     * @type {String}
     */
    this.token = '';
  }

  /**
   * Determines if the API is authenticated
   * @return {Boolean}
   */
  _isAuthenticated() {
    this._restoreCachedToken();
    return !!this.token;
  }

  /**
   * Used to Authorize the MGO API for all subsequent requests
   * Will set the class instance token on success
   * @return {Promise<Boolean>}
   */
  async _getAuthToken(clientId = 'mgo-app') {
    if (this._isAuthenticated()) {
      return Promise.resolve(true);
    }

    const { access_token: token } = await post(`${this.api}/mgo-auth/oauth/token`, {}, {
      params: {
        grant_type: 'client_credentials',
        client_id: clientId,
      },
    });

    this.token = token || '';
    this._setTokenCache(this.token, addHours(new Date(), 1).toISOString());

    if (!this.token) {
      // eslint-disable-next-line no-console
      console.error(new Error('could not authenticate api'));
    }

    return Promise.resolve(!!this.token);
  }

  // eslint-disable-next-line class-methods-use-this
  _setTokenCache(token, expiresOn) {
    sessionStorage.setItem('apit', token);
    sessionStorage.setItem('apitexp', expiresOn);
  }

  // eslint-disable-next-line class-methods-use-this
  _clearTokenCache() {
    sessionStorage.removeItem('apit');
    sessionStorage.removeItem('apitexp');
  }

  _restoreCachedToken() {
    const token = sessionStorage.getItem('apit') || '';
    const expiresOn = sessionStorage.getItem('apitexp') || '';

    if (parseISO(expiresOn) && isAfter(new Date(), parseISO(expiresOn))) {
      this.token = '';
      return;
    }

    if (token) {
      this.token = token;
    }
  }

  reset() {
    this.numLoginAttempts = 0;
    this.cfpsId = '';
    this.loginZIP = '';
    this.visitId = null;
    sessionStorage.removeItem('coacv');
    sessionStorage.removeItem('coaccfpsid');
    sessionStorage.removeItem('coaccode');
  }

  /**
   * Logout users
   */
  logout() {
    this.reset();
  }

  /**
   * Retrieve Application Configuration Details
   * These config items relate to Command Center
   * @method configGet
   * @return {Promise<Config>}
   */
  async configGet() {
    await this._getAuthToken();
    if (!this._isAuthenticated()) {
      return notAuthenticatedErrorPromise;
    }

    return get(`${this.api}/mgo-api/api/v1/app/config`, {}, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  }

  /**
   * COAC Actions
   * ----------------------------------------------------------------
   */

  /**
   * Login to existing COA order
   * @param {String} cfpsId - 16 digit COA order confirmation code
   * @param {String} zip5 - COA order new address zip5
   * @return {Promise<COA>}
   */
  async login(cfpsId, zip5) {
    await this._getAuthToken();
    if (!this._isAuthenticated()) {
      return notAuthenticatedErrorPromise;
    }

    const raw = await post(`${this.api}/mgo-api/api/v1/coac/login`, {
      cfpsId,
      numAttempts: this.numLoginAttempts,
      zip5,
    }, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });

    if (raw?.data?.status === 500) {
      return Promise.resolve('There was an issue processing your request. Please try again.');
    }

    if (raw?.xhr?.error) {
      this.numLoginAttempts += 1;
      return Promise.resolve(raw?.xhr?.message);
    }

    this.numLoginAttempts = 0;
    this.cfpsId = cfpsId;
    this.loginZip = zip5;

    sessionStorage.setItem('coaccfpsid', cfpsId);
    sessionStorage.setItem('coaccode', zip5);
    sessionStorage.setItem('visitId', raw.visitId);
    this.visitId = raw.visitId;

    return Promise.resolve('');
  }

  /**
   * Search for order COA data
   * @return {Promise<COA>}
   */
  async search() {
    await this._getAuthToken();
    if (!this._isAuthenticated()) {
      return notAuthenticatedErrorPromise;
    }

    try {
      const response = await post(`${this.api}/mgo-api/api/v1/coac/search`, {
        cfpsId: this.cfpsId,
        visitId: this.visitId,
        zip5: this.loginZip,
      }, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
      const { cfpsId, modCoacCfpsId } = response;
      sessionStorage.setItem('coaccfpsid', cfpsId);
      this.cfpsId = cfpsId;
      this.modCoacCfpsId = modCoacCfpsId;
      return response;
    } catch (err) {
      return Promise.resolve({});
    }
  }

  async verifyActivationCode({ activationPasscode }) {
    await this._getAuthToken();
    if (!this._isAuthenticated()) {
      return notAuthenticatedErrorPromise;
    }
    try {
      const response = await fetch(`${this.api}/mgo-api/api/v1/coac/validateactivationcode/`, {
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify({
          cfpsId: this.cfpsId, mgoActivationCode: activationPasscode, visitId: this.visitId,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
      });
      if (response.ok) {
        const resp = await this.activateCoa();
        return resp;
      }
      const {
        exception: passcodeException, ...rest
      } = await response.json();
      return { ...rest, passcodeException };
    } catch (error) {
      return Promise.resolve(error);
    }
  }

  async activateCoa() {
    await this._getAuthToken();
    if (!this._isAuthenticated()) {
      return notAuthenticatedErrorPromise;
    }
    try {
      const response = await fetch(`${this.api}/mgo-api/api/v1/coac/activatecoa`, {
        mode: 'cors',
        method: 'PUT',
        body: JSON.stringify({ cfpsId: this.cfpsId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
      });
      if (response.ok) {
        return response;
      }
      const {
        exception: passcodeException, ...rest
      } = await response.json();
      return { ...rest, passcodeException };
    } catch (error) {
      return Promise.resolve(error);
    }
  }

  async sendActivationEmail(uuid) {
    await this._getAuthToken();
    if (!this._isAuthenticated()) {
      return notAuthenticatedErrorPromise;
    }

    const raw = await post(`${this.api}/mgo-api/api/v1/email/${uuid}/confirmation/send`, {}, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });

    if (raw?.data?.status === 500) {
      return Promise.resolve('There was an issue processing your request.');
    }

    return Promise.resolve('');
  }


  /**
    * Save a COA correction
    * @param {String} firstName
    * @param {String} lastName
    * @param {String} email
    * @param {String} homePhone
    * @param {String} mobilePhone
    * @param {String} moverType - INDIVIDUAL | FAMILY | BUSINESS
    * @param {String} forwardType - PERMANENT | TEMPORARY
    * @param {String} startDate
    * @param {String} endDate - TEMPORARY filers
    * @param {Address} oldAddress
    * @param {Address} newAddress
    * @param {Boolean} coacInformedDeliveryOptIn - opted into informed delivery in COAC flow
    * @return {Promise<COA>}
    */
  async save({
    firstName,
    lastName,
    email,
    homePhone = null,
    mobilePhone = null,
    moverType,
    forwardType,
    startDate,
    endDate = null,
    oldAddress,
    newAddress,
    coacInformedDeliveryOptIn = false,
  }) {
    await this._getAuthToken();
    if (!this._isAuthenticated()) {
      return notAuthenticatedErrorPromise;
    }

    return post(`${this.api}/mgo-api/api/v1/coac/save`, {
      cfpsId: this.cfpsId,
      visitId: this.visitId,
      zip5: this.loginZip,
      firstName,
      lastName,
      email,
      homePhone,
      mobilePhone,
      moverType,
      forwardType,
      startDate,
      stopDate: endDate, // TODO coordinate with BE to make this consistent with MGO
      oldAddress,
      newAddress,
      coacInformedDeliveryOptIn,
    }, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  }

  /**
   * Cancel a COA order
   * @property {String} reasonCode - CAN | FRD | NAME | ADDR
   * @return {Promise<Boolean>}
   */
  async cancel(reasonCode) {
    await this._getAuthToken();
    if (!this._isAuthenticated()) {
      return notAuthenticatedErrorPromise;
    }

    const { error } = await post(`${this.api}/mgo-api/api/v1/coac/cancel`, {
      cancelReason: reasonCode,
      cfpsId: this.cfpsId,
      visitId: this.visitId,
      zip5: this.loginZip,
    }, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
    return Promise.resolve(!error);
  }

  /**
   * EMF Actions
   * ----------------------------------------------------------------
   */

  /**
   * Get Global Payment forwarding Information
   * @param {String} returnUrl - The url to return a user to if they use GP Back
   * @param {Number} optionId - selected extension option
   * @return {Promise<GlobalPaymentResponse>}
   */
  async globalPaymentURLGet({ returnUrl, optionId }) {
    await this._getAuthToken();
    if (!this._isAuthenticated()) {
      return notAuthenticatedErrorPromise;
    }

    return post(`${this.api}/mgo-api/api/v1/coac/payment`, {
      cfpsId: this.cfpsId,
      newZip5: this.loginZip,
      optionId,
      postbackUrl: this.postbackURL,
      returnUrl,
      visitId: this.visitId,
    }, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  }

  /**
   * Verify EMF order after payment
   * @param {String} extensionId - EMF UUID
   * @return {Promise}
   */
  async orderCommit(extensionId) {
    await this._getAuthToken();
    if (!this._isAuthenticated()) {
      return notAuthenticatedErrorPromise;
    }

    const url = `${this.api}/mgo-api/api/v1/coac/payment/commit`;
    const item = url.split('?');
    const outUrl = url?.replace(`?${item[1]}`, `/commit?${item[1]?.replace('/commit', '')}`);

    const resp = post(outUrl, {
      extensionId,
      cfpsId: this.cfpsId,
      newZip5: this.loginZip,
      visitId: this.visitId,
    }, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
    sessionStorage.removeItem('visitId');
    return resp;
  }

  /**
   * MYMOVE Actions
   * ----------------------------------------------------------------
   */

  /**
   * Get Access code to build handoff url
   * @return {Promise<HandoffCodeResponse>}
   */
  async handoffCodeGet() {
    await this._getAuthToken();
    if (!this._isAuthenticated()) {
      return notAuthenticatedErrorPromise;
    }

    return get(
      `${this.api}/mgo-api/api/v1/handoffs?cfpsId=${this.cfpsId}&newZip=${this.loginZip}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  }

  /**
   * Analytics Events
   * ----------------------------------------------------------------
   */

  /**
   * Get the visitId
   * @param {String} deviceType - DESKTOP | MOBILE
   * @param {String} queryString - url query string
   * @return {Promise<Number>}
   */
  async visit(deviceType, queryString) {
    await this._getAuthToken();

    if (!this._isAuthenticated()) {
      return notAuthenticatedErrorPromise;
    }
    const visitId = JSON.parse(sessionStorage.getItem('visitId'));
    if (!visitId) {
      this.visitId = await post(
        `${this.api}/mgo-api/api/v1/e/v`,
        {
          params: {
            qs: queryString,
            deviceType,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      );
      sessionStorage.setItem('visitId', this.visitId);
    }
    this.visitId = visitId;
    return Promise.resolve(this.visitId);
  }

  async logExceptions({
    exception,
    oldAddress,
    newAddress,
    zip5,
    startDate,
    stopDate,
  }) {
    await this._getAuthToken();
    if (!this._isAuthenticated()) {
      return notAuthenticatedErrorPromise;
    }

    const resp = post(`${this.api}/mgo-api/api/v1/exception/coac`, {
      exception,
      oldAddress,
      newAddress,
      cfpsId: this.cfpsId,
      zip5,
      startDate,
      stopDate,
      visitId: this.visitId,
    }, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
    return resp;
  }
}
