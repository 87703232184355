import Vue from 'vue';
import VueRouter from 'vue-router';
import { isDesktop } from '@mgo/core/src/utils/deviceType';

Vue.use(VueRouter);

/**
 * Authorized only route guard
 * To be used in beforeEnter to prevent unauthorized users
 * from entering routes that require authorization
 * @param {Object} to - the target Route Object being navigated to
 * @param {Object} from - the current route being navigated away from
 * @param {Function} next - move on to the next hook or confirm navigation if no more hooks
 */
export const authorizedOnly = async (to, from, next) => {
  if (!sessionStorage.getItem('coaccfpsid')) {
    next({ name: 'home' });
  } else {
    next();
  }
};

const baseRoute = 'mgoc';

const routes = [
  {
    path: '/',
    redirect: { name: 'home' },
  },
  {
    path: `/${baseRoute}/home`,
    name: 'home',
    component: () => (isDesktop()
      ? import(/* webpackChunkName: 'bootstrap' */ '../views/Home/desktop.vue')
      : import(/* webpackChunkName: 'bootstrap' */ '../views/Home/mobile.vue')),
  },
  {
    path: `/${baseRoute}/review`,
    name: 'review',
    beforeEnter: authorizedOnly,
    component: () => (isDesktop()
      ? import(/* webpackChunkName: 'review' */ '../views/Review/desktop.vue')
      : import(/* webpackChunkName: 'review' */ '../views/Review/mobile.vue')),
  },
  {
    path: `/${baseRoute}/edit`,
    name: 'edit',
    beforeEnter: authorizedOnly,
    component: () => (isDesktop()
      ? import(/* webpackChunkName: 'review' */ '../views/Edit/desktop.vue')
      : import(/* webpackChunkName: 'review' */ '../views/Edit/mobile.vue')),
  },
  {
    path: `/${baseRoute}/fatal-error`,
    name: 'fatal-error',
    component: () => import(/* webpackChunkName: 'error' */ '../views/FatalError.vue'),
  },
  {
    path: `/${baseRoute}/processing-error`,
    name: 'processing-error',
    component: () => import(/* webpackChunkName: 'error' */ '../views/ProcessingError.vue'),
  },
  {
    path: `/${baseRoute}/disputes`,
    name: 'disputes',
    component: () => (isDesktop()
      ? import(/* webpackChunkName: 'disputes' */ '../views/Disputes/desktop.vue')
      : import(/* webpackChunkName: 'disputes' */ '../views/Disputes/mobile.vue')),
  },
  {
    path: `/${baseRoute}/disputes/questionnaire`,
    name: 'disputes-questionnaire',
    beforeEnter: authorizedOnly,
    component: () => (isDesktop()
      ? import(/* webpackChunkName: 'disputes' */ '../views/Questionnaire/desktop.vue')
      : import(/* webpackChunkName: 'disputes' */ '../views/Questionnaire/mobile.vue')),
  },
  {
    path: `/${baseRoute}/cancel`,
    name: 'cancel',
    beforeEnter: authorizedOnly,
    component: () => (isDesktop()
      ? import(/* webpackChunkName: 'bootstrap' */ '../views/Cancel/desktop.vue')
      : import(/* webpackChunkName: 'bootstrap' */ '../views/Cancel/mobile.vue')),
  },
  {
    path: `/${baseRoute}/confirm-cancel`,
    // will need a query param with the reason code passed in
    // ex: https://coactst02.imagitas.com/mgoc/confirm-cancel?reason=CAN
    name: 'confirm-cancel',
    beforeEnter: authorizedOnly,
    component: () => (isDesktop()
      ? import(/* webpackChunkName: 'bootstrap' */ '../views/ConfirmCancel/desktop.vue')
      : import(/* webpackChunkName: 'bootstrap' */ '../views/ConfirmCancel/mobile.vue')),
  },
  {
    path: `/${baseRoute}/extended-mail-forwarding`,
    name: 'extended-mail-forwarding',
    beforeEnter: authorizedOnly,
    component: () => (isDesktop()
      ? import(/* webpackChunkName: 'emf' */ '../views/ExtendedMailForwarding/desktop.vue')
      : import(/* webpackChunkName: 'emf' */ '../views/ExtendedMailForwarding/mobile.vue')),
  },
  {
    path: `/${baseRoute}/action`,
    name: 'action',
    beforeEnter: authorizedOnly,
    component: () => import(/* webpackChunkName: 'payment' */ '../views/Action.vue'),
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
